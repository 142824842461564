import React, {useEffect, useState} from "react";
import ArrowButton from "./ArrowButton";
import Players from "./Players/Players";
import Board from "./Board";
import Pot from "./Pot";
import ProgressLine from "./ProgressLine";
import ResetButton from "./ResetButton";

function Table({state, updateEventIndex, onReset}) {
    // - Get table state

    const tableState = state.replayHistory[state.eventIndex]
    const participants = tableState.PlayerIDs.map((item) => tableState.Participants[item])

    const [pots, setPots] = useState(Object.values(tableState.Pots))

    useEffect(() => {
        setPots(Object.values(tableState.Pots));
    }, [tableState.Pots]);

    useEffect(() => {
        document.title = tableState.PokerNetwork + ": " + tableState.GameID;
    }, []);

    // - Return output
    return (
        <div className='table'>

            {/* - Pots */}
            <div className='pot__container'>
                {pots.map((item, index) => (
                    <Pot className={pots.length > 3 ? 'more-pots' : ''} key={index} bank={item}/>
                ))}
            </div>

            {/* - Board */}

            <Board board={tableState.Board}/>

            {/* - PlayersPosition */}

            <Players players={participants} gameType={tableState.GameType} numPocketCards={tableState.NumPocketCards}/>

            {/* - ButtonChangeEvent */}

            <div className='reset-button__container'>
                <ResetButton onReset={onReset}/>
            </div>
            <div className='arrow-button__container'>
                <ArrowButton value={'-'} updateEventIndex={updateEventIndex}/>
                <ArrowButton value={'+'} updateEventIndex={updateEventIndex}/>
            </div>
            <ProgressLine currentIndex={state.eventIndex} historyLength={state.replayHistory.length}/>
        </div>
    )
}

export default Table;
