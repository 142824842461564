import React from "react";
import Card from "../../Card";
import Dealer from "./Dealer";
import Bet from "./Bet";
import PlayerAction from "../../PlayerAction";
import Stack from "./Stack";
import Profit from "./Profit";
import PlayerId from "./PlayerId";

function Player({player, gameType, position, itemSize, isEmpty, profitColor, numPocketCards}) {
    function getCards(pocketCards, gameType, isFolded, numPocketCards) {
        const result = []

        if (pocketCards?.length === 0) {
            return [...new Array(numPocketCards)].map((val, index) => <Card key={index} cardCode={'back'}
                                                                        className={`${isFolded ? "card--folded" : ""} ${numPocketCards > 2 ? "card--small" : ""}`}/>);
        } else {
            for (let index = 0; index < numPocketCards; index++) {
                const cardCode = pocketCards.slice(index * 2, index * 2 + 2);
                result.push(<Card key={cardCode} cardCode={cardCode} className={`${isFolded ? "card--folded" : ""} ${numPocketCards > 2 ? "card--small" : ""}`}/>)
            }
            return result
        }
    }

    return !isEmpty ? (<>


        {/* - List of elements */}

        <div className={'player player' + position} id={position}>
            {/* - Bet */}

            {player.Bet ? <Bet className={'bet'} bet={player.Bet}/> : null}

            {/* - PlayerID */}

            <PlayerId player={player}/>

            {/* - Button */}

            {player.PositionName === 'BTN' ? <Dealer/> : null}

            {/* - Event */}

            {!player.CurrentStreetAction || player?.Profit ? null : <PlayerAction player={player}/>}

            {/* - Cards */}

            <div className="dot">
                {getCards(player.VisibleCards, gameType, player.IsFolded, numPocketCards)}
                {/*    todo later: pass game type properly */}
            </div>

            {/* - Stack */}

            <Stack stack={player.Stack}/>

            {/* - Profit */}

            <Profit profit={player.Profit} color={profitColor}/>

        </div>
    </>) : (

        <div className={'player player' + position} id={position}>
        <PlayerId player={player} isEmpty={isEmpty}/>
        <div className="dot">
            </div>

            <Stack stack={0} isEmpty={isEmpty}/>
        </div>
    )
}

export default Player;
